import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";
import AboutImg from '../../assets/about.webp';
import AboutImg2 from '../../assets/about-2.webp';
import AboutIcon1 from '../../assets/at_icon1.webp';
import AboutIcon2 from '../../assets/at_icon2.webp';
import AboutIcon3 from '../../assets/at_icon3.webp';

const AboutUs =()=>{

    return(<>
        <ComponenetHeader
           name={'About Us'}
        />

          <section className="about_section-1">
          <div className="container">
            <div className="row justify-content-around align-items-center">
                    <div className="col-md-5 mb-3 mb-md-0">
                    <div className="about_image">
                    <img src={AboutImg} className='img-fluid' alt={AboutImg} />
                    </div>
                    </div>

                    <div className="col-md-5 mb-3 mb-md-0">
                    <div className="section-title mb-2 text-left">
                       <p>- ABOUT COMPANY -</p>
                       <h2>Work With Reliable On Road Truck <br/>Repair Services</h2>
                    </div>
                    <div className="about_content text-left">
                    <p className="mb-0">Our revolutionary search software application is created to provide you the most accurate repair shops
                        information within seconds along with the shortest route possible. When your commercial truck/trailer/rv
                        stops running, or you need assistance with reefer dealers/repair shops or you need emergency towing
                        service or you are looking for a tire shop, you name it and we provide you with all the information
                        on service providers related to Break Down. Our customer service team is available 24/7/365 for any
                        help in using our software application or help in finding truck/trailer/rv repair/breakdown service
                        providers.</p>
                    </div>
                    </div>

         </div>
         </div>
        </section>

        <section className="about_section-3">
          <div className="container">
             <div className="row justify-content-center align-items-center">

              <div className="col-md-12">
              <div className="section-title mb-md-5 mb-2 pb-md-3 pb-2">
                  <p>- Prominent Service Providers -</p>
                  <h2>Promote Your Business with BreakdownInc</h2>
              </div>
              </div>

              <div className="col-md-6 pr-md-5">
                <div className="about_highlights mb-4">
                  <span className="about_icon">
                    <img src={AboutIcon1} className='img-fluid' alt={AboutIcon1}/>
                  </span>
                  <h3>Swift Access</h3>
                  <p>Fleet managers and drivers demand swift and reliable access to service vendors nationwide.
                    Our platform ensures your business is readily discoverable when services are
                    needed in your region.
                  </p>
                </div>

                <div className="about_highlights mb-4">
                  <span className="about_icon">
                    <img src={AboutIcon2} className='img-fluid' alt={AboutIcon2}/>
                  </span>
                  <h3>Broad Audience</h3>
                  <p>With thousands of fleet managers and truck drivers relying on the Breakdown Inc website and apps daily,
                    your business will connect with a vast network. This brings a continuous stream of new and repeat customers.
                  </p>
                </div>

                <div className="about_highlights">
                  <span className="about_icon">
                    <img src={AboutIcon3} className='img-fluid' alt={AboutIcon3}/>
                  </span>
                  <h3>Measurable Impact</h3>
                  <p>Our detailed analytics on numerous listings annually show significant business growth for our vendors.
                    While success varies by location and services offered, vendors typically experience a return on
                    investment exceeding ten times their initial cost. Partner with us and see your business flourish!
                  </p>
                </div>

              </div>

              <div className="col-md-6">
                <div className="about_screenshots">
                <img src={AboutImg2} className='img-fluid' alt={AboutImg2}/>
                </div>
              </div>

             </div>
          </div>
        </section>

        </>
    );
};

export default AboutUs;