import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";

const PrivacyPolicy =()=>{

    return(<>

        <ComponenetHeader
           name={'Privacy Policy'}
        />

        <section className="content_page text-left">
        <div className="container">
            <div className="row content_page_style">
             <div className="col-md-12">
               <h2 className="mb-4">Privacy Policy</h2>
               <h3>Introduction</h3>
               <p>We at Breakdown Inc. are committed to respecting your online privacy, and recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us.</p>
               <p>Breakdown Inc. has established this Online Privacy Policy so that you can understand the care with which we intend to treat your Personal Information.</p>
               <p>Personal Information means any information that may be used to identify an individual including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or at home, as well as any other information about that individual.</p>
               <p>This online privacy policy may be amended by us at any time or from time to time, without specific notice to You. The latest online privacy policy will be posted on this website (and appropriately date-stamped), and you Should review any amended online privacy policy prior to re-using this website. Whether or not you review this Online privacy policy, in its initial or any amended version, you will be bound by its terms.</p>
             </div>

             <div className="col-md-12">
             <h3>Cookies</h3>
             <p>"Cookies" are pieces of data your browser stores on your computer's hard drive. They enable us to determine when you or someone using your computer returns to our website. Cookies make your visit a more personalized experience by identifying special offers or relationships. Most Web browsers automatically accept cookies but, if you like, you can edit your browser options to block them. However, please note that, if you block the use of cookies, you may experience less than optimal performance of our website. "Cookies" are pieces of data your browser stores on your computer's hard drive. They enable us to determine when you or someone using your computer returns to our website. Cookies make your visit a more personalized experience by identifying special offers or relationships. Most Web browsers automatically accept cookies but, if you like, you can edit your browser options to block them. However, please note that, if you block the use of cookies, you may experience less than optimal performance of our website.</p>
             </div>

             <div className="col-md-12">
              <h3>Use of Your Personal Information</h3>
              <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
              <ul>
                <li>To present our Site and its contents to you.</li>
                <li>To provide you with information, products or services that you request from us.</li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>To provide you with notices about your subscriptions to the Service, including expiration and renewal notices.</li>
                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                <li>To notify you about changes to our Site or any products or services we offer or provide though it.</li>
                <li>To allow you to participate in interactive features on our Site.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>For any other purpose with your consent.</li>
                <li>We never sell your personal information or provide it to others for marketing purposes.</li>
                <li>On the Site, Mobile Site and Apps.</li>
                <li>Through mobile and desktop applications you download from the Site or in connection with the Service.</li>
                <li>In e-mail, SMS/text and other electronic messages between you and the Site. You can access breakdown information by connecting with us on Google+, Facebook, Twitter, LinkedIn and YouTube. These third parties may collect information about you and your online activities, either on the Site or on other websites, through cookies, web beacons, and other technologies. Please remember that breakdown does not have access to, or control over, the information these third parties may collect. The information practices of these third parties are not covered by this privacy policy. Your use of third party sites is subject to the privacy policies as provided for by each third party site and is your responsibility to review.</li>
              </ul>
             </div>

             <div className="col-md-12">
             <h4>Disclosure of Your Personal Information</h4>
             <p>If you choose to provide us with your Personal Information, breakdown may disclose that Personal Information as follows:</p>
             <ul>
                <li>To a third party if said Personal Information was provided through a web site branded by said third party or</li>
                <li>To a third party who requires such information in order to assist breakdown in its general administration and/or business operations or</li>
                <li>To a third party who expresses an interest in acquiring some or all of breakdown business operations or</li>
                <li>To a third party where such disclosure is required or permitted by law.</li>
             </ul>

              <p>While breakdown does not 'sell' or otherwise distribute Personal Information about User Pass Account holders to any third party, Breakdown Inc at its discretion, may publish just the Company Names of User Pass Account holders.</p>
              <p>Breakdown, at its discretion, may also disclose to a third party the contact information of some or all of breakdown listed Vendors if Breakdown Inc, acting reasonably, is satisfied that such third party will only use that contact information to make a product and/or service offering to those listed Vendors which could be of interest to some or all of them.</p>
              <p>In the event that a User Pass Account holder provides breakdown with feedback regarding an experience that such holder had with a listed Vendor, Breakdown Inc may provide some or all of that feedback to that Vendor, but only with the prior express consent of such holder.</p>
             </div>

             <div className="col-md-12">
              <h4>Consent</h4>
              <p>If you choose to provide us with your Personal Information, you hereby consent to our collection, use, disclosure and retention of that Information for the purposes described in this Online Privacy Policy.</p>
              <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by e-mail to the primary e-mail address specified in your account and/or possibly through a notice on the Site home page. The date the privacy policy was last revised is identified at the top of this page. You are responsible for ensuring we have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our Site and this privacy policy to check for any changes.</p>
             </div>

             <div className="col-md-12">
              <h4>Security Safeguards</h4>
              <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Site like message boards. The information you share in public areas may be viewed by any user of the Site. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.</p>
             </div>

             <div className="col-md-12">
              <h4>Additional Information</h4>
              <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at <strong>info@breakdowninc.com</strong></p>
             </div>

            </div>
        </div>

        </section>



        </>
    );
};

export default PrivacyPolicy;