import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Modal, Row, Col, Tabs, Tab } from 'react-bootstrap';
import PlanService from '../../service/PlanService';
import ComponenetHeader from '../common/ComponenetHeader';
import { Alert, Spinner } from "react-bootstrap";
import EmailService from '../../service/EmailService';
import imageService from '../../service/ImageServie';

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [key, setKey] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', formMessage: '' });
  const [message, setMessage] = useState({ text: '', variant: '' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await PlanService.getAllPlans();
      setPlans(response);
      if (response.length > 0) {
        setKey(response[0].id); // Set the first plan's id as the default active tab
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handleShow = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts
    setMessage({ text: '', variant: '' }); // Clear previous messages

    try {
      const response = await EmailService.sendEmail(formData);
      setMessage({ text: [response], variant: 'success' });
      setFormData({ name: '', email: '', phone: '', formMessage: '' });
    } catch (error) {
      setMessage({ text: `Error: ${error.message}`, variant: 'danger' });
    } finally {
      setLoading(false);
    }
  }


  const handleClose = () => setShowModal(false);
  return (<>

    <section className="plan-section">
      <Container>
        <Row>
          <div className='plan_title'>
            <h2 className="text-center">Choose Your Plan</h2>
            <p className="text-center">Discover the plan that suits you best. Opt for the flexibility of a monthly
              subscription or the <br/>convenience of a yearly plan - whichever meets your needs.</p>
          </div>
        </Row>

        <Row className='mt-4 plan_tabs'>
          <Tabs
            id="plan-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-5"
          >
            {plans.map((plan) => (
              <Tab eventKey={plan.id} title={plan.name} key={plan.id}>

                <Col md={12} className="mb-md-4 px-0">
                  <Card className="text-start p-md-2 p-0">
                    <Row className='mx-0'>
                      {plan.imageName && (
                        <Col md={3} className='p-0 align-content-around ad_banner'>
                          <Card.Img
                            variant="top"
                            src={imageService.getPlanImage+'/'+plan.imageName}
                            alt={plan.name}
                          />
                        </Col>
                      )}

                      <Col className='pe-md-0' md={plan.imageName ? 9 : 12}>

                        <Card.Body className='p-0 planCard-style h-100 position-relative'>
                          <Row>
                            <Col md={12} className='planInfo-style'>
                              <div className='row'>
                                <div className='col-md-6'>
                                  <div className='cardText'>
                                    <span className='ad_miles'>0.0 Miles</span>
                                    <Card.Title>Your Company Name</Card.Title>
                                    <p className='ad_address mb-0'><i className="fas fa-location-dot"></i> Address, city, state</p>
                                    <p className='ad_email mb-0'><i className="fa fa-envelope"></i> contact@demo.com</p>
                                    <div className="ad_description">
                                      <p className='mb-0'>{plan.description}</p>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-md-6 px-md-2'>
                                  <div className='bd_plan_contact'>
                                    <button className='btn' type="button"><i className="fa fa-phone"></i> 702-780-0780</button>
                                  </div>
                                  <div className='ad_services'>
                                    <h6 className='ad_worktype'>Work Type : <b>Shop/Mobile</b></h6>
                                    <ul className='ad_serviceList'>
                                      <li>Road Service</li>
                                      <li>Electrical System</li>
                                      <li>New Tires Sales</li>
                                      <li>Fleet Acounts</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                         <div className='row'>
                             <div className='col-md-12'>
                               <div className='ad_special_offer px-md-2'>
                                <div className='highlight_text'>
                                  <h3>Highlights</h3>
                                </div>
                                <div className='special_offer_content'>
                                  <ul>
                                    <li>Exclusive Deals!</li>
                                    <li>Special Offers!</li>
                                    <li>Custom Messages!</li>
                                  </ul>
                                </div>
                               </div>
                             </div>
                          </div>

                          <div className='row'>
                            <div className='absolute_priceDiv d-flex justify-content-between align-items-center px-md-0 w-100'>
                              <div className='home_plan_price'>
                                {/* <h5>{plan.name} </h5>  */}
                                <h3><sup>$</sup>{plan.price}<span> / Per Month</span></h3>
                              </div>
                              <div className="planPrice-style">
                                <Button className='planBtn shine position-relative' variant="primary" onClick={() => handleShow(plan)}>Get {plan.name}</Button>
                              </div>
                            </div>
                          </div>


                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Tab>
            ))}
          </Tabs>
        </Row>

        {/* Modal for contact details */}
        <Modal className='quote_modal' show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Get A Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedPlan && (<>

              <div className='form_content'>
                <p className='mb-4'>Thank you for your interest in our <b>{selectedPlan.name} plan</b>. We’re thrilled to offer you our exceptional
                  services and are here to help you every step of the way!</p>
              </div>

              <div className='quote_form'>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group cont_form">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Your Name"
                          required
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group cont_form">
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder="Phone"
                          required
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group cont_form">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email"
                          required
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group cont_form">
                        <textarea
                          className="form-control h-auto"
                          rows="3"
                          cols="50"
                          name="formMessage"
                          value={formData.formMessage}
                          onChange={handleChange}
                          placeholder="Write a message"
                          required
                          disabled={loading}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      {message.text && (
                        <Alert variant={message.variant} className="mt-0 py-2 text-center">
                          {message.text}
                        </Alert>
                      )}
                      <div className="form_btn mt-1">
                        <button type="submit" className="btn w-100" disabled={loading}>
                          {loading ? (
                            <>
                              <Spinner animation="border" size="sm" role="status" className="me-2" />
                              Sending...
                            </>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>
                    </div>

                  </Row>
                </form>
              </div>

            </>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </section>

  </>
  );
};

export default Plans;