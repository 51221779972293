import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Image, Spinner, Alert } from "react-bootstrap";
import AdvertisementService from "../service/AdvertisementService";
import ServicesService from "../service/ServicesService";
import AdCard from "../component/advertisement/AdCard";
import imageService from "../service/ImageServie";
import LocationService from "../service/LocationService";
import SubcategoryService from "../service/SubcategoryService";
import CityLinks from "../component/common/CityLinks";
import { Helmet } from "react-helmet";
import StickeyContent from "../component/common/StickeyContent";
import RelatedBlog from "../component/common/RelatedBlog";

const AdDetailsPage = () => {

  const { adId, searchlocation } = useParams();
  const formattedLoaction = searchlocation?.trim().replace(/-/g, ', ');
  const [location, setLocation] = useState(null);
  const [ads, setAds] = useState([]);
  const [advertisement, setAdvertisement] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const subcategoryId = sessionStorage.getItem("subcategoryId");
  const navigate = useNavigate();

  // Fetch subcategory when component mounts
  useEffect(() => {
    const fetchSubcategory = async () => {
      try {
        const data = await SubcategoryService.getSubcategoriesByCategory();
        const matchedSub = data.find(sub => sub.id == subcategoryId);
        if (matchedSub) {
          setSubcategory(matchedSub);
        } else {
          setSubcategory(data[0]);
        }
      } catch (error) {
        console.error('Error fetching location:', error.message);
      }
    };
    fetchSubcategory();
  }, [subcategoryId]);

  // Fetch Location when component mounts or location changes
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        if (formattedLoaction) {
          const response = await LocationService.getLocationByLocation(formattedLoaction);
          setLocation(response);
        }
      } catch (error) {
        console.error('Error fetching location:', error.message);
      }
    };
    fetchLocation();
  }, [formattedLoaction]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAdvertisement = async () => {
      setLoading(true);
      setError(null);
      try {
        const adData = await AdvertisementService.getAdvertisementById(adId);
        setAdvertisement(adData);
        if (adData.serviceIds) {
          const response = await ServicesService.getServiceByIds(adData.serviceIds);
          const serviceNames = response.map(service => service.name);
          setServices(serviceNames);
        }
      } catch (error) {
        setError("Oops! this Ad is currently not available, please find other related service below");
      } finally {
        setLoading(false);
      }
    };
    fetchAdvertisement();
  }, [adId]);

  useEffect(() => {
    const fetchAdvertisements = async () => {
      if (location) {
        try {
          const params = {
            subcategoryId: subcategoryId || advertisement?.subcategoryIds[0] || subcategory.id || null, // Ensure subcategoryId is sent as null if not available
            city: location?.city?.name || null,
            state: location?.state?.name || null,
            country: location?.country?.name || "United States",
            userLat: location.latitude,
            userLon: location.longitude,
            sortByDistance: true
          };
          const adData = await AdvertisementService.searchAdvertisements(params);
          setAds(adData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAdvertisements();
  }, [location]);

  const formatPhoneNumber = (phoneNumber) => {
    // Format phone number as (XXX) XXX-XXXX
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handleViewDetails = (adId, companyName) => {
    window.scrollTo(0, 0);
    // Redirect to the advertisement details page
    const CompanyName = companyName.toString().trim().replace(/\s+/g, '-');
    navigate(`/details/${adId}/${CompanyName}/${searchlocation}`);
  };

  const handleCityClick = (selectedCity) => {
    window.scrollTo(0, 0);
    const formattedCity = selectedCity.name.toString().trim().replace(/\s+/g, '_');
    const formattedSub = subcategory.name.toString().trim().replace(/\s+/g, '-');
    sessionStorage.setItem("subcategoryId", subcategory.id);
    const url = `/service-near-me/${formattedSub}/${formattedCity}-${selectedCity.state.code}-${selectedCity.country.code}`;
    navigate(url);
  };

  return (<>

    <Helmet>
      <title>
        {`${advertisement?.companyName}, ${advertisement?.city}, ${advertisement?.state} | ${advertisement?.phone} | Breakdown Inc`}
      </title>
    </Helmet>

    {!loading && !error && advertisement && (
      <section className="breadcrumb_section detail_breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">{advertisement?.companyName}</li>
                  <li className="breadcrumb-item active" aria-current="page">{advertisement?.city}, {advertisement?.state}, {advertisement?.country}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    )}

    <section className="ad_detail_sec">
      <div className="container">
        <div className="row justify-content-center">
          {/* Loading and Error Display */}

          {loading && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {error && (
            <Alert variant="danger" className="mt-md-3 mt-1 text-center w-75">
              {error}
            </Alert>
          )}

          {/* Advertisement Details */}
          {!loading && !error && advertisement && (<>
            <div className="col-md-12 list_detail_outer">
              <div className="row justify-content-around align-items-center">
                <div className="col-md-8 order-md-1 order-2">
                  {!loading && !error && advertisement && (<>
                    <section className="ad_detail_sec2">
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                            <div className="ad_detail_info mb-md-3 mb-2">
                              <h3 className="ad_company">{advertisement.companyName}</h3>
                              <p className='ad_address mb-2'><i className="fas fa-location-dot"></i> {advertisement.address}, {advertisement.city}, {advertisement.state}, {advertisement.country}</p>
                            </div>
                            <div className="detail_bottom_content text-left">
                              <div className="ad_services">
                                <h5>Services Offered</h5>
                                <div className="card p-0 bg-transparent text-white border-0">
                                  <ul className="detail_service">
                                    {services.map((service, index) => (
                                      <li key={index}>{service}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="ad_descrip mt-md-3 mt-2">
                                <h6>Description</h6>
                                <div className="card p-0 border-0 bg-transparent text-white">
                                  <p>{advertisement.description || 'Not Available'}</p>
                                </div>
                              </div>
                              <div className="ad_more_info d-flex justify-content-between">
                                <p className="ad_email"><i className="fa fa-envelope"></i> <strong>Email :</strong> <a href={`mailto:${advertisement.email}`}>{advertisement.email || 'Not Available'}</a></p>
                                <p className="ad_website"><i className="fa fa-globe"></i> <strong>Website :</strong> <a href={advertisement.website} target="_blank" rel="noopener noreferrer">{advertisement.website || 'Not Available'}</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </section>
                  </>
                  )}
                </div>
                <div className="col-md-3 order-md-2 order-1">
                  {advertisement.imageName && (
                    <div className="ad_detail_banner mb-3">
                      <Image src={`${imageService.getAdsImage}${advertisement.imageName}`} fluid />
                    </div>
                  )}
                  <div className="ad_contact_info">
                    <div className="ad_phone text-center">
                      <a href={`tel:${advertisement.phone}`}><i className="fa fa-phone"></i> {formatPhoneNumber(advertisement.phone)}</a>
                    </div>
                    <p className="ad_hours text-center">
                      <i className="far fa-clock"></i> {advertisement.workingHours}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
          )}
        </div>
      </div>
    </section>

    <section className="related_ads">
      <div className="container">
        <div className="row justify-content-center">
          <div className="related_title col-md-12">
            <h4>Explore more services in {location?.city?.name}, {location?.state?.name || 'United States'}</h4>
          </div>
          <div className="related_ads_list row justify-content-center col-md-12">
            {ads.filter(ad => ad.id !== advertisement?.id).map(ad => (
              <AdCard
                key={ad.id}
                advertisement={ad}
                onViewDetails={handleViewDetails}
              />
            ))}
          </div>
        </div>
      </div>
    </section>

    <section className="service_nearby">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="nearby_title">{subcategory?.name} Service near me in {location?.state?.name || 'United States'}</h4>
          </div>
          <div className="col-md-12">
            <div className="service_nearby_list">
              <CityLinks
                subcategory={subcategory?.name}
                countryId={location?.country?.countryid || '1'}
                stateId={location?.state?.stateid || '5'}
                keyword='Service near me in'
                handleCityClick={(city) => handleCityClick(city)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <RelatedBlog />
    <section className="roadside_nearby">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="roadside_title">Roadside Assistance near me in {location?.state?.name || 'United States'}</h4>
          </div>
          <div className="col-md-12">
            <div className="roadside_nearby_list">
              <CityLinks
                countryId={location?.country?.countryid || '1'}
                stateId={location?.state?.stateid || '5'}
                keyword='Roadside Assistance near me in'
                handleCityClick={(city) => handleCityClick(city)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <StickeyContent
      formatedSubcategory={subcategory?.name}
      formattedLoaction={formattedLoaction}
    />
  </>
  );
};
export default AdDetailsPage;