import React from "react";
import Mobileapp from '../../assets/mobile-app.webp';
import playstore from '../../assets/googleplay.webp';
import appstore from '../../assets/appstore.webp';
import ComponenetHeader from "../common/ComponenetHeader";

const MobileAppLink = () =>{

    return(
        <>

        <ComponenetHeader
           name={'Mobile App'}
        />

        <section className="d_app_section text-left">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="app_content pe-md-5">
                            <h3>Breakdown Inc Mobile App</h3>
                            <p>
                            Breakdown Inc is the largest online directory service provider specializing in the trucking Industry.
                            Our directory harbors a listing of most truck service companies including Truck Repair Shops, Trailer
                            Repair Shops, Weigh Stations, Scales, Locksmiths, Truck Parts, Reefer Repair Shops, Insurance companies,
                            Truck/Trailer Rentals, Truck Wash Locations and many more. Breakdown Inc offer Motor Carrier Companies,
                            their drivers & Truck Owner Operators a simple way to connect with potential truck repair service
                            providers whenever they have a breakdown.Our revolutionary search software
                            application "Breakdown Inc" is created to provide you the most accurate repair shops
                            information within seconds along with the shortest route possible.
                            </p>
                            <h5>Now Available on iOS and Android</h5>
                            <div className="app_btn_img d-flex">
                              <a target="blank" href="https://apps.apple.com/us/app/breakdown-inc/id6739872583"><img src={appstore} className='img-fluid' alt={appstore}/></a>
                              <a target="blank" href="https://play.google.com/store/apps/details?id=com.breakdowninc.com&hl=en"><img src={playstore} className='img-fluid' alt={playstore}/></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="app_screenshot text-center">
                         <img src={Mobileapp} className='img-fluid' alt={Mobileapp}/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="app_content mt-md-3 mt-2">
                        <p>
                        When your commercial Truck/Trailer/RV stops running, or you need assistance with reefer Dealers/Repair shops or you
                        need Emergency Towing service or you are looking for a Tire shop, you name it and we provide
                        you with all the information on service providers related to BREAKDOWN.
                        Our customer service
                        team is available 24/7/365 for any help in using our software application or help in finding
                        Truck/Trailer/RV repair/breakdown service providers. Our network is multiplying every minute
                        so we can provide you with the breakdown service providers in every city, state, and region of
                        North America. Find nearby Mobile truck breakdown services on our "Breakdown Inc" mobile app.
                        </p>
                        </div>
                    </div>


                </div>
            </div>
        </section>


        </>
    );
};

export default MobileAppLink;